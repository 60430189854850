var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      true
        ? _c("feather-icon", {
            attrs: {
              title: "Edit",
              icon: "Edit3Icon",
              svgClasses: "h-5 w-5 mr-4 hover:text-primary cursor-pointer",
            },
            on: { click: _vm.editRecord },
          })
        : _vm._e(),
      _c("feather-icon", {
        attrs: {
          title: "Duplicate",
          icon: "CopyIcon",
          svgClasses: "h-5 w-5 mr-4 hover:text-primary cursor-pointer",
        },
        on: { click: _vm.duplicateRecord },
      }),
      true
        ? _c("feather-icon", {
            attrs: {
              title: "Delete",
              icon: "TrashIcon",
              svgClasses: "h-5 w-5 hover:text-danger cursor-pointer",
            },
            on: { click: _vm.confirmDeleteRecord },
          })
        : _vm._e(),
      _c(
        "vs-prompt",
        {
          attrs: {
            title: "Delete Widget",
            "accept-text": "Delete",
            active: _vm.showDeleteDialog,
          },
          on: {
            cancel: _vm.closeDeleteDialog,
            close: _vm.closeDeleteDialog,
            accept: _vm.deleteWwidget,
            "update:active": function ($event) {
              _vm.showDeleteDialog = $event
            },
          },
        },
        [
          _c("div", { staticClass: "vx-row mt-2 mb-4" }, [
            _c("div", { staticClass: "vx-col" }, [
              _vm.deleteAffectedUsersCount > 0
                ? _c("span", [
                    _vm._v(
                      "You are about to delete a role assigned to " +
                        _vm._s(0) +
                        " user(s). Select a replacement role for these user(s)."
                    ),
                  ])
                : _vm._e(),
              _vm.deleteAffectedUsersCount == 0
                ? _c("span", [
                    _vm._v("Are you sure you want to delete this role?"),
                  ])
                : _vm._e(),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }