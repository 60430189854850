var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "vx-row widget-instance" }, [
    _c(
      "div",
      { staticClass: "vx-col w-full mb-base" },
      [
        _c(
          "vx-card",
          [
            _c("div", { staticClass: "flex flex-wrap justify-start mb-3" }, [
              _c("img", {
                staticStyle: { height: "32px", "border-radius": "3px" },
                attrs: { src: _vm.widgetIcon },
                on: { error: _vm.setAltImgForIcon },
              }),
              _c("h3", { staticClass: "ml-2 mt-1" }, [
                _vm._v(_vm._s(_vm.widgetName)),
              ]),
            ]),
            _c("div", { staticClass: "vx-row" }, [
              _c("div", { staticClass: "vx-col w-full mb-4" }, [
                _c("span", {
                  domProps: { innerHTML: _vm._s(_vm.widgetDescription) },
                }),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "vx-row" },
              _vm._l(_vm.screenshotUrls, function (screenshot, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "vx-col w-full md:w-1/4 lg:w-1/4 mb-6",
                  },
                  [
                    _c("img", {
                      staticClass: "responsive widget-instance-screenshot",
                      attrs: { src: screenshot },
                      on: { error: _vm.setAltImgForScreenshot },
                    }),
                  ]
                )
              }),
              0
            ),
            _c("div", { staticClass: "vx-row" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "mr-4 sm:mb-0 mb-2",
                      on: { click: _vm.addWidgetInstance },
                    },
                    [_vm._v("Add new widget")]
                  ),
                ],
                1
              ),
            ]),
            _c("h4", { staticClass: "mt-8" }, [
              _vm._v("My " + _vm._s(_vm.widgetName) + " Widgets"),
            ]),
            _c("ag-grid-vue", {
              ref: "agGridTable",
              staticClass: "ag-theme-material w-100 my-4 ag-grid-table",
              attrs: {
                components: _vm.cellRendererComponents,
                columnDefs: _vm.columnDefs,
                defaultColDef: _vm.defaultColDef,
                rowData: _vm.widgetInstances,
                gridOptions: _vm.gridOptions,
                rowSelection: "single",
                pagination: true,
                context: _vm.context,
                paginationPageSize: _vm.paginationPageSize,
                domLayout: _vm.domLayout,
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }