var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "flex items-center" },
        [
          _c(
            "vs-button",
            { staticClass: "mt-1", on: { click: _vm.handleInstall } },
            [_vm._v("Add to website")]
          ),
        ],
        1
      ),
      _c(
        "vs-popup",
        {
          attrs: {
            title: "Widget Embed Instructions",
            active: _vm.showPopup,
            "class-content": "install-widget-popup",
            "data-backdrop": "static",
            "data-keyboard": "false",
          },
          on: {
            "update:active": function ($event) {
              _vm.showPopup = $event
            },
          },
        },
        [
          _c("h5", [_vm._v("Elements Script")]),
          _c("p", { staticClass: "mt-3" }, [
            _vm._v(
              "Copy and paste this code into the head section of your page HTML. This only needs to be added once per website or page, no matter how many widgets you place on the page."
            ),
          ]),
          _c(
            "div",
            { staticClass: "embed-code mt-3 p-3" },
            [
              _c(
                "vs-button",
                {
                  directives: [
                    {
                      name: "clipboard",
                      rawName: "v-clipboard:copy",
                      value: _vm.elementScript,
                      expression: "elementScript",
                      arg: "copy",
                    },
                    {
                      name: "clipboard",
                      rawName: "v-clipboard:success",
                      value: _vm.onCopy,
                      expression: "onCopy",
                      arg: "success",
                    },
                    {
                      name: "clipboard",
                      rawName: "v-clipboard:error",
                      value: _vm.onError,
                      expression: "onError",
                      arg: "error",
                    },
                  ],
                  staticClass: "float-right ml-2",
                },
                [_vm._v("Copy\n      ")]
              ),
              _c("p", { staticClass: "embed-script" }, [
                _vm._v(_vm._s(_vm.elementScript)),
              ]),
            ],
            1
          ),
          _c("h5", { staticClass: "mt-8" }, [
            _vm._v("Widget Installation Code"),
          ]),
          _c("p", { staticClass: "mt-3" }, [
            _vm._v(
              "Copy and paste this code into the desired place of your widget (HTML editor, website template, theme, etc)."
            ),
          ]),
          _c(
            "div",
            { staticClass: "embed-code mt-3 p-3" },
            [
              _c(
                "vs-button",
                {
                  directives: [
                    {
                      name: "clipboard",
                      rawName: "v-clipboard:copy",
                      value: _vm.installationScript,
                      expression: "installationScript",
                      arg: "copy",
                    },
                    {
                      name: "clipboard",
                      rawName: "v-clipboard:success",
                      value: _vm.onCopy,
                      expression: "onCopy",
                      arg: "success",
                    },
                    {
                      name: "clipboard",
                      rawName: "v-clipboard:error",
                      value: _vm.onError,
                      expression: "onError",
                      arg: "error",
                    },
                  ],
                  staticClass: "float-right ml-2",
                },
                [_vm._v("Copy")]
              ),
              _c("p", { staticClass: "embed-script" }, [
                _vm._v(_vm._s(_vm.installationScript)),
              ]),
            ],
            1
          ),
          _c("h5", { staticClass: "mt-8" }, [
            _vm._v("Using the Duda platform plugin to add your widgets?"),
          ]),
          _c("p", { staticClass: "mt-3" }, [
            _vm._v("If you are using the Duda "),
            _c("strong", [_vm._v("Travelgenix Widgets")]),
            _vm._v(
              " site widget, just select this widget from the dropdown and enter the following Widget ID in the field: "
            ),
            _c("strong", [_vm._v(_vm._s(_vm.getWidgetId))]),
          ]),
          _c("h5", { staticClass: "mt-8" }, [_vm._v("Looking for more help?")]),
          _c("p", { staticClass: "mt-3" }, [
            _vm._v("\n      Click "),
            _c(
              "a",
              {
                attrs: {
                  href: "https://support.travelgenix.io/hc/en-gb/categories/360003654398--Website-Integrations",
                  target: "_blank",
                  rel: "nofollow",
                },
              },
              [_vm._v("here")]
            ),
            _vm._v(
              " to read our support article on adding widgets to your website.\n    "
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }